
$( document ).ready(function() {



  $(".SetYourselfFree .film__content__player").click(function(){
      $(".SetYourselfFree .film__content").addClass('novisible');
      $(".SetYourselfFree .film__film").addClass('showvisible');
      //$(".SetYourselfFree").addClass('centerOfScreen');
      var videoURL = $('#video1').prop('src');
      videoURL += "&autoplay=1";
      $('#video1').prop('src',videoURL);
      //scrollToMiddle('#film__content__player');    
  });
  

  $(".PersonalizeYourRide .film__content__player").click(function(){
      $(".PersonalizeYourRide .film__content").addClass('novisible');
      $(".PersonalizeYourRide .film__film").addClass('showvisible');
      //$(".SetYourselfFree").addClass('centerOfScreen');
      var videoURL2 = $('#video2').prop('src');
      videoURL2 += "&autoplay=1";
      $('#video2').prop('src',videoURL2);
      //scrollToMiddle('#film__content__player');    
  });



  
})



